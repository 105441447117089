import { render, staticRenderFns } from "./workType.vue?vue&type=template&id=fc3b7ad6&scoped=true&"
import script from "./workType.vue?vue&type=script&lang=js&"
export * from "./workType.vue?vue&type=script&lang=js&"
import style0 from "./workType.vue?vue&type=style&index=0&id=fc3b7ad6&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.9.8@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fc3b7ad6",
  null
  
)

export default component.exports