<template>
  <div class="super">
    <div class="head"></div>
    <div class="main" v-if="inFlag == 1">
      <div class="tool-item" @click="pickTool(2)">
        <Icon class="icon1" type="ios-refresh-circle" />
        <div class="label">重置密码</div>
      </div>
      <div class="tool-item" @click="pickTool(3)">
        <Icon class="icon2" type="ios-paper" />
        <div class="label">企业审批</div>
      </div>
      <div class="tool-item" @click="pickTool(4)">
        <Icon class="icon3" type="md-person-add" />
        <div class="label">更改企业账号</div>
      </div>
      <div class="tool-item" @click="pickTool(5)">
        <Icon class="icon4" type="ios-text" />
        <div class="label">任务审核</div>
      </div>
      <div class="tool-item" @click="pickTool(6)">
        <Icon class="icon5" type="ios-calculator" />
        <div class="label">排行榜数据</div>
      </div>
      <div class="tool-item" @click="pickTool(7)">
        <Icon class="icon5" style="color:#bb47ca;" type="ios-ribbon" />
        <div class="label">学习中心数据</div>
      </div>
      <div class="tool-item" @click="pickTool(8)">
        <Icon class="icon5" style="color:#57c6fb;" type="md-contacts" />
        <div class="label">人员数据监控</div>
      </div>
      <div class="tool-item" @click="pickTool(9)">
        <Icon class="icon5" style="color:#5457b5;" type="ios-paper" />
        <div class="label">签名审批</div>
      </div>
      <div class="tool-item" @click="pickTool(10)">
        <Icon class="icon5" style="color:#ea5914;" type="ios-mail" />
        <div class="label">短信监控</div>
      </div>
      <div class="tool-item" @click="pickTool(11)">
        <Icon class="icon5" style="color:#ea5914;" type="ios-filing" />
        <div class="label">设置总分包项目</div>
      </div>
      <div class="tool-item" @click="pickTool(12)">
        <Icon class="icon5" style="color:#ea5914;" type="ios-filing" />
        <div class="label">修改总分包项目</div>
      </div>
      <div class="tool-item" @click="pickTool(13)">
        <Icon class="icon5" style="color:#ea5914;" type="ios-filing" />
        <div class="label">工种管理</div>
      </div>
      <div class="tool-item" @click="pickTool(14)">
        <Icon class="icon5" style="color:#ea5914;" type="ios-filing" />
        <div class="label">红包配置</div>
      </div>
      <div class="tool-item" @click="pickTool(15)">
        <Icon class="icon5" style="color:#ea5914;" type="ios-filing" />
        <div class="label">红包流水</div>
      </div>
      <div class="tool-item" @click="pickTool(16)">
        <Icon class="icon5" style="color:#ea5914;" type="ios-filing" />
        <div class="label">数字证书开关配置</div>
      </div>
      <div class="tool-item" @click="pickTool(17)">
        <Icon class="icon5" style="color:#ea5914;" type="ios-filing" />
        <div class="label">人脸识别切换</div>
      </div>
    </div>
    <div class="toolMain" v-if="inFlag != 1">
      <div class="toolHead" @click="backIndex()">
        <Icon class="backIcon" type="ios-arrow-back" />返回
        <div class="headtitle">{{inText}}</div>
      </div>
      <div v-if="inFlag == 2" class="toolBox">
        <div class="toolboxstyle">
          <Input v-model="changePhone" placeholder="输入账号" style="width: 300px;margin-bottom:10px;" />
          <Button
            type="primary"
            style="width:150px;margin-bottom:10px;"
            @click="toChangePassword"
          >确认修改</Button>
        </div>
      </div>
      <div v-if="inFlag == 3" class="toolBox">
        <div>
          <div class="requestList">
            <div class="r-item" v-for="(item,index) in userList" :key="index">
              <div class="info">
                <div class="info-li">
                  <div class="label">姓名：</div>
                  {{item.vlinkMan}}
                </div>
                <div class="info-li">
                  <div class="label">电话：</div>
                  {{item.vlinkTel}}
                </div>
                <div class="info-li">
                  <div class="label">企业名称：</div>
                  {{item.vname}}
                </div>
                <div class="info-li">
                  <div class="label">创建时间：</div>
                  {{item.createTime}}
                </div>
              </div>
              <div class="right">
                <Button style="margin-right:4px;" type="primary" @click="toApply(1,item.id)">通过</Button>
                <Button type="error" @click="toApply(2,item.id)">不通过</Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="inFlag == 4" class="toolBox">
        <div class="changeMan">
          <Input
            v-model="changeOldPhone"
            placeholder="输入旧账号手机号"
            style="width: 300px;margin-bottom:10px;"
          />
          <Input
            v-model="changeNewName"
            placeholder="输入新账号名字"
            style="width: 300px;margin-bottom:10px;"
          />
          <Input
            v-model="changeNewPhone"
            placeholder="输入新账号手机号"
            style="width: 300px;margin-bottom:10px;"
          />
          <Button type="primary" style="width:150px;margin-bottom:10px;" @click="toChangeMan">确认修改</Button>
        </div>
      </div>
      <div v-if="inFlag == 5" class="toolBox">
        <Spin fix v-if="getVideoListLoading">
          <Icon type="ios-loading" size="18" class="demo-spin-icon-load"></Icon>
          <div>Loading</div>
        </Spin>
        <div class="userVideoBox">
          <div class="videoSearchBox">
            <div class="csb-item">
              <div class="label">姓名：</div>
              <Input v-model="videoSearchName" placeholder="输入搜索姓名" clearable style="width: 200px" />
            </div>
            <div class="csb-item">
              <div class="label">手机号：</div>
              <Input
                v-model="videoSearchPhone"
                placeholder="输入搜索手机号"
                clearable
                style="width: 200px"
              />
            </div>
            <div class="csb-item">
              <Button type="primary" style="margin-right: 20px" @click="toSearchVideo">搜索</Button>
              <Button @click="toResetSearchVideo">重置</Button>
            </div>
          </div>
          <div class="uv-li" v-for="(item,index) in getVideoListData" :key="index">
            <div class="userInfo">
              <div class="info-item">
                <div class="label">姓名：</div>
                <div class="text">{{item.userName}}</div>
              </div>
              <div class="info-item">
                <div class="label">手机号：</div>
                <div class="text">{{item.phone}}</div>
              </div>
              <div class="info-item">
                <div class="label">项目名称：</div>
                <div class="text">{{item.projectName}}</div>
              </div>
              <div class="info-item">
                <div class="label">班组名称：</div>
                <div class="text">{{item.teamName}}</div>
              </div>
              <div class="info-item">
                <div class="label">任务名：</div>
                <div class="text">{{item.taskName}}</div>
              </div>
              <div class="info-item">
                <div class="label">上传时间：</div>
                <div class="text">{{item.createTime}}</div>
              </div>
            </div>
            <div class="videoBox">
              <video
                v-if="item.url"
                :src="item.url"
                controls="controls"
                width="150"
                height="auto"
              >您的浏览器不支持 video 标签。</video>
            </div>
            <div class="videoBox">
              <img :src="item.faceidImage" style="width:80px;height:auto;" alt />
            </div>
            <div class="vbtnbox">
              <Button type="primary" style="margin-right: 10px" @click="toPastVideo(item,'2')">通过</Button>
              <Button type="warning" @click="toPastVideo(item,'0')">不通过</Button>
            </div>
          </div>
          <div class="pagebox">
            <Page :total="getVideoListTotal" @on-change="handelGetVideoPageChange" />
          </div>
        </div>
      </div>
      <div v-if="inFlag == 6" class="toolBox">
        <div class="sec-title">
          <div class="shu"></div>当前接口状态
        </div>
        <div class="status-box">
          <div>返回码：{{apiCode}}</div>
          <div>状态：{{apiStatus}}</div>
        </div>
        <div class="sec-title">
          <div class="shu"></div>监控数据表
        </div>
        <Table :size="'small'" :max-height="'750'" stripe :columns="columns1" :data="data1"></Table>
      </div>
      <div v-if="inFlag == 7" class="toolBox">
        <div class="sec-title">
          <div class="shu"></div>当前接口状态
        </div>
        <div class="status-box">
          <div>返回码：{{apiCode}}</div>
          <div>状态：{{apiStatus}}</div>
        </div>
        <div class="sec-title">
          <div class="shu"></div>监控数据表
        </div>
        <Table
          :size="'small'"
          :loading="getWatchStatLoading"
          :max-height="'750'"
          stripe
          :columns="columns2"
          :data="data2"
        ></Table>
        <div class="pagebox">
          <Page :total="getWatchStatTotal" @on-change="handelGetWatchStatChange" />
        </div>
      </div>
      <div v-if="inFlag == 8" class="toolBox">
        <div class="user-list">
          <div class="ul-title">请选择人员数据列表</div>
          <Dropdown trigger="click" style="margin-left: 20px">
            <a href="javascript:void(0)">
              {{currentUserList.title}}
              <Icon type="ios-arrow-down"></Icon>
            </a>
            <DropdownMenu slot="list">
              <DropdownItem v-for="(item, index) in d" :key="index" @click.native="pickUserList(item)">{{item.title}}</DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </div>
        <div v-if="currentUserList.key == 6" style="width: 100%;display: flex;justify-content: flex-end;padding: 0 40px;margin-bottom: 10px;">
          <Button type="primary" @click="toUpdateCheckStatuss">批量更新考勤机状态</Button>
        </div>
        <Table
          :size="'small'"
          :loading="getUserListLoading"
          :max-height="'750'"
          stripe
          :columns="userColumns"
          :data="userDataList"
        > 
          <template slot-scope="{ row, index }" slot="status">
            <div class="btnbox-flex">
              {{row.status == '1'?'正常':'断连'}}
            </div>
          </template>
          <template slot-scope="{ row, index }" slot="action">
            <div class="btnbox-flex">
              <Button v-if="currentUserList.key == 6" class="bf-style" style="marigin-right: 6px;" type="primary" @click="toUpdateCheckStatus(row)">更新状态</Button>
              <Button v-if="currentUserList.key == 5" class="bf-style" style="marigin-right: 6px;" type="success" @click="toIssuePersons(row)">下发</Button>
              <Button v-if="currentUserList.key == 5" class="bf-style" type="primary" @click="toOpenImg(row)">查看照片</Button>
              <Button v-if="currentUserList.key == 2" class="bf-style" type="success" @click="toSendTask(row)">下发</Button>
              <Button v-if="currentUserList.key == 3 || currentUserList.key == 4" class="bf-style" type="success" @click="toSendTask1(row)">下发</Button>
            </div>
          </template>
        </Table>
        <div class="pagebox">
          <Page :current="getUserPage" :total="getUserTotal" @on-change="handelGetUserListChange" :page-size="getUserSize" />
        </div>
      </div>
      <div v-if="inFlag == 9" class="toolBox">
        <div>
          <div class="requestList">
            <div class="r-item" v-for="(item,index) in signList" :key="index">
              <div class="info">
                <div class="info-li">
                  <div class="label">企业名称：</div>
                  {{item.vprojectname}}
                </div>
                <div class="info-li">
                  <div class="label">企业代码：</div>
                  {{item.vprojectcode}}
                </div>
              </div>
              <div class="right">
                <Button style="margin-right:4px;" type="primary" @click="toApplySign(item.id)">通过</Button>
              </div>
            </div>
          </div>
          <div class="pagebox">
            <Page :total="getSignTotal" :page-size="getSignSize" @on-change="handelGetSignListChange" />
          </div>
        </div>
      </div>
      <div v-if="inFlag == 10" class="toolBox">
        <div>
          <div class="requestList">
            <div class="r-item" v-for="(item,index) in messageList" :key="index">
              <div class="info info2">
                <div class="info-li">
                  <div class="label" style="width: auto;margin-right: 14px;">项目名称</div>：
                  {{item.projectName}}
                </div>
                <div class="info-li">
                  <div class="label" style="width: auto;margin-right: 14px;">项目在线培训人数</div>：
                  {{item.currentCount}}
                </div>
                <div class="info-li">
                  <div class="label" style="width: auto;margin-right: 14px;">项目总人数</div>：
                  {{item.totalCount}}
                </div>
                <div class="info-li">
                  <div class="label" style="width: auto;margin-right: 14px;">按进度要求培训人数</div>：
                  {{item.asRequestCount}}
                </div>
                <div class="info-li">
                  <div class="label" style="width: auto;margin-right: 14px;">完成培训人数</div>：
                  {{item.competeCount}}
                </div>
                <div class="info-li">
                  <div class="label" style="width: auto;margin-right: 14px;">未按进度完成培训人数</div>：
                  {{item.unCompeteCount}}
                </div>
                <div class="info-li">
                  <div class="label" style="width: auto;margin-right: 14px;">超7天人数</div>：
                  {{item.overSevenDayCount}}
                </div>
                <div class="info-li">
                  <div class="label" style="width: auto;margin-right: 14px;">超两周人数</div>：
                  {{item.overFourteenDayCount}}
                </div>
              </div>
              <div class="right">
                <Button v-if="item.isRemoved == 0" style="margin-right:4px;" type="warning" @click="toUpdateMsg(item.projectId,1)">关闭</Button>
                <Button v-if="item.isRemoved == 1" style="margin-right:4px;" type="success" @click="toUpdateMsg(item.projectId,0)">开启</Button>
                <Button style="margin-right:4px;" type="primary" @click="toSendMsg(item.projectId)">发送</Button>
              </div>
            </div>
          </div>
          <div class="pagebox">
            <Page :total="getMessageTotal" :page-size="getMessageSize" @on-change="handelGetMessageListChange" />
          </div>
        </div>
      </div>
      <div v-if="inFlag == 11" class="toolBox">
        <Spin fix v-if="getSubcLoading">
          <Icon type="ios-loading" size="18" class="demo-spin-icon-load"></Icon>
          <div>Loading</div>
        </Spin>
        <div>
          <div class="projectSearchBox">
            <div class="csb-item">
              <div class="label">当前选择的总包项目：</div>
              <div class="info">
                <span>{{subcontractProject1.vprojectname ? subcontractProject1.vprojectname : '请选择总包项目'}}</span>
                <div class="closeBtn" @click="delSubc(1)" v-if="subcontractProject1.vprojectname">
                  <Icon type="md-close" />
                </div>
              </div>
            </div>
            <div class="csb-item">
              <div class="label">当前选择的分包项目：</div>
              <div class="info">
                <span>{{subcontractProject2.vprojectname ? subcontractProject2.vprojectname : '请选择分包项目'}}</span>
                <div class="closeBtn" @click="delSubc(2)" v-if="subcontractProject2.vprojectname">
                  <Icon type="md-close" />
                </div>
              </div>
            </div>
            <div class="csb-item" style="flex-wrap: wrap;">
              <div class="label" style="margin-bottom: 10px;">请输入搜索项目名：</div>
              <Input
                v-model="getSubcSearch"
                placeholder="输入搜索项目名"
                clearable
                style="width: 200px;margin-right: 20px;margin-bottom: 10px;"
              />
              <div>
                <Button type="primary" style="margin-right: 20px;margin-bottom: 10px;" @click="searchSubc">搜索</Button>
                <Button style="margin-bottom: 10px;" @click="resetSearchSubc">重置</Button>
              </div>
            </div>
            <div class="csb-item">
              <Button type="success" @click="toSetSubc">设置总分包项目</Button>
            </div>
          </div>
          <div class="requestList">
            <div class="r-item" v-for="(item,index) in subcList" :key="index" style="flex-wrap: wrap;padding-bottom: 14px;">
              <div class="info info2">
                <div class="info-li">
                  <div class="label" style="text-align-last: auto;width: 80px;">项目id</div>：
                  {{item.id}}
                </div>
                <div class="info-li">
                  <div class="label" style="text-align-last: auto;width: 80px;">项目名称</div>：
                  {{item.vprojectname}}
                </div>
              </div>
              <div class="right">
                <Button style="margin-right:4px;" type="info" @click="setProject(item,1)">设置为总包项目</Button>
                <Button style="margin-right:4px;" type="success" @click="setProject(item,2)">设置为分包项目</Button>
              </div>
            </div>
          </div>
          <div class="pagebox">
            <Page :total="subcListTotal" :page-size="subcListSize" @on-change="handelSubcListChange" />
          </div>
        </div>
      </div>
      <div v-if="inFlag == 12" class="toolBox">
        <div>
          <div class="requestList">
            <div class="r-item" v-for="(item,index) in editSubcList" :key="index">
              <div class="info info2">
                <div class="info-li">
                  <div class="label" style="width: auto;margin-right: 14px;">总包项目名</div>：
                  {{item.turnkeyProjectName}}
                </div>
                <div class="info-li">
                  <div class="label" style="width: auto;margin-right: 14px;">分包项目名</div>：
                  {{item.subContractProjectName}}
                </div>
                <div class="info-li">
                  <div class="label" style="width: auto;margin-right: 14px;">更新时间</div>：
                  {{item.updateTime}}
                </div>
              </div>
              <div class="right">
                <Button style="margin-right:4px;" type="error" @click="delEditSubcList(item)">删除</Button>
              </div>
            </div>
          </div>
          <div class="pagebox">
            <Page :total="editSubcTotal" :page-size="editSubcSIze" @on-change="handelEditSubcListChange" />
          </div>
        </div>
      </div>
      <div v-if="inFlag == 13" class="toolBox">
        <div>
          <WorkTypeMan />
          <!-- <div class="requestList">
            <div class="r-item" v-for="(item,index) in editSubcList" :key="index">
              <div class="info info2">
                <div class="info-li">
                  <div class="label" style="width: auto;margin-right: 14px;">总包项目名</div>：
                  {{item.turnkeyProjectName}}
                </div>
                <div class="info-li">
                  <div class="label" style="width: auto;margin-right: 14px;">分包项目名</div>：
                  {{item.subContractProjectName}}
                </div>
                <div class="info-li">
                  <div class="label" style="width: auto;margin-right: 14px;">更新时间</div>：
                  {{item.updateTime}}
                </div>
              </div>
              <div class="right">
                <Button style="margin-right:4px;" type="error" @click="delEditSubcList(item)">删除</Button>
              </div>
            </div>
          </div>
          <div class="pagebox">
            <Page :total="editSubcTotal" :page-size="editSubcSIze" @on-change="handelEditSubcListChange" />
          </div> -->
        </div>
      </div>
      <!-- 红包配置 -->
      <div v-if="inFlag == 14" class="toolBox">
        <div>
        <div class="projectSearchBox">
          <div class="csb-item">
            <div class="label">人均支出金额：</div>
            <Input placeholder="请输入人均支出金额" v-model="redPacketData.totalRewardAmount" clearable style="width: 200px" /> <span>元</span>
          </div>
          <div class="csb-item">
            <div class="label">红包开关：</div>
            <Checkbox v-model="redPacketData.redPacketStatus">是否开启</Checkbox>
          </div>
          <div class="csb-item">
            <div class="label">红包发布时间：</div>
            <!-- <Checkbox-group v-model="redPacketData.releaseDatecheckList"> -->
              <!-- 1、MONDAY：周一； 
                    2、TUESDAY：周二； 
                    3、WEDNESDAY：周三； 
                    4、THURSDAY：周四； 
                    5、FRIDAY：周五；
                    6、SATURDAY：周六； 
                    7、SUNDAY：周日。 -->
              <!-- <Checkbox label="MONDAY">周一</Checkbox>
              <Checkbox label="TUESDAY">周二</Checkbox>
              <Checkbox label="WEDNESDAY">周三</Checkbox>
              <Checkbox label="THURSDAY">周四</Checkbox>
              <Checkbox label="FRIDAY">周五</Checkbox>
              <Checkbox label="SATURDAY">周六</Checkbox>
              <Checkbox label="SUNDAY">周日</Checkbox>
            </Checkbox-group> -->
            <RadioGroup v-model="redPacketData.releaseDate">
              <Radio label="MONDAY">周一</Radio>
              <Radio label="TUESDAY">周二</Radio>
              <Radio label="WEDNESDAY">周三</Radio>
              <Radio label="THURSDAY">周四</Radio>
              <Radio label="FRIDAY">周五</Radio>
              <Radio label="SATURDAY">周六</Radio>
              <Radio label="SUNDAY">周日</Radio>
            </RadioGroup>
          </div>
          <div class="csb-item" style="align-items: flex-start">
            <div class="label">红包分配规则：</div>
            <div>
              <Button type="primary" @click="editRedConfig('add')">新增</Button>
              <!-- <div style="margin:5px 0;"><span>剩余人均可用金额：{{6}}元</span></div> -->
            </div>
          </div>
          <div>
          <Table
            :size="'small'"
            :max-height="'750'"
            stripe
            :columns="userColumns14"
            :data="redPacketDataList"
            :loading="getRedPacketStatLoading"
          >
          <template slot-scope="{ row }" slot="rewardAmount">
            <span>{{row.rewardAmount/100}}</span>
          </template>
          <template slot-scope="{ row, index }" slot="action">
            <a @click="editRedConfig('edit',row, index)">编辑</a>
            &nbsp;&nbsp;
            <!-- <el-popover
              placement="top"
              width="160"
              v-model="delRedConfigVisible">
              <p>确定移除吗？</p>
              <div style="text-align: right; margin: 0">
                <el-button size="mini" type="text" @click="visible = false">取消</el-button>
                <el-button type="primary" size="mini" @click="visible = false">确定</el-button>
              </div>
              <el-button slot="reference">删除</el-button>
            </el-popover> -->
            <a style="color:red;" @click="delRedConfig(row, index)">移除</a>
          </template>
          </Table>
          </div>
          <div class="csb-item" style="margin: 5px 0 ">
            <div class="label">红包图标：</div>
            <el-upload 
              action="/"
              name="picture"
              accept=".jpg, .png"
              list-type="picture-card"
              :limit="0"
              :file-list="redPacketImages"
              :on-preview="handlePictureCardPreview"
              :before-upload="onBefore"
              :on-change="handleChange"
              >
                <i class="el-icon-plus"></i>
            </el-upload>
            <el-dialog :visible.sync="dialogVisible" size="full">
                <img width="100%" :src="dialogImageUrl" alt="">
            </el-dialog>
          </div>
          <div class="csb-item">
            <div class="label">红包标语：</div>
            <Input placeholder="请输入红包标语" v-model="redPacketData.redPacketTitle" clearable style="width: 200px" />
          </div>
          <div class="csb-item">
              <Button type="primary" style="margin:5px 10px;" @click="onSaveRedConfig">保存</Button>
              <Button type="primary" @click="backIndex()">取消</Button>
          </div>
        </div>
        </div>
          <!-- 新增/编辑分配规则 -->
        <Modal
          v-model="redPacketConfigModal"
          :loading="redPacketModelLoading"
          :mask-closable="false"
          :title="redConfigModal.type=='add'?'新增分配规则':'编辑分配规则'"
          :close-on-click-modal="false"
          @on-ok="setRedPacketMode"
          @on-cancel="cancelRedPacketMode"
        >
          <div style="display:flex;align-items: center;margin-bottom:5px;">
            <div class="label">时间：</div>
            <Input v-model="redConfigModal.time" placeholder="输入时间" style="width:60%;" />周
            <!-- <span v-show="redConfigModal.timeStat" style="color:red;">请填写时间</span> -->
          </div>
          <div style="display:flex;align-items: center">
            <div class="label">金额：</div>
            <Input v-model="redConfigModal.rewardAmount" placeholder="输入金额" style="width:60%" />元
            <!-- <span v-show="redConfigModal.rewardAmount" style="color:red;">请填写金额</span> -->
          </div>
        </Modal>
      </div>
      <!-- 红包流水 -->
      <div v-if="inFlag == 15" class="toolBox">
        <!-- <Card style="width: 100%; margin-bottom: 20px; max-width: 1300px"> -->
          <div style="width:100%;padding:10px;height:70px;">
            <div style="display: flex;align-items: center; float:right;">
              <div class="label">项目：</div>
              <el-cascader 
                clearable
                :props="props"
                placeholder="请选择项目"
                :show-all-levels="false"
                @change="projectChange"
                v-model="projectData"
              >
                <!-- :change-on-select="changeOnSelect" -->
              </el-cascader>
            </div>
          </div>
          <div style="display: flex;justify-content: space-around;margin-bottom: 20px;">
            <Card style="width: 100%; margin-top: 10px; max-width: 210px">
              <div>
                <p>
                  已发放红包金额（元）
                </p>
                <div style="width:100%;text-align:center;margin-top:5px;">
                  {{redPacketStatData.redPacketTotalAmount/100}}
                </div>
              </div>
            </Card>
            <Card style="width: 100%; margin-top: 10px; max-width: 210px">
              <div>
                <p>
                  已被领取红包金额（元）
                </p>
                <div style="width:100%;text-align:center;margin-top:5px;">
                  {{redPacketStatData.receivedTotalAmount/100}}
                </div>
              </div>
            </Card>
            <Card style="width: 100%; margin-top: 10px; max-width: 210px">
              <div>
                <p>
                  已发放红包数量（个）
                </p>
                <div style="width:100%;text-align:center;margin-top:5px;">
                  {{redPacketStatData.redPacketTotalNumber}}
                </div>
              </div>
            </Card>
            <Card style="width: 100%; margin-top: 10px; max-width: 210px">
              <div>
                <p>
                  已被领取的红包数量（个）
                </p>
                <div style="width:100%;text-align:center;margin-top:5px;">
                  {{redPacketStatData.receivedTotalNumber}}
                </div>
              </div>
            </Card>
          </div>
          <div class="c-btnbox">
            <div style="display: flex;align-items: center;">
              <div style="display: flex;align-items: center;">
                <div class="label">姓名：</div>
                <Input
                  placeholder="请输入姓名"
                  style="width: 200px; margin-right: 14px"
                  v-model="packetWaterProjectSearch.keyword"
                />
              </div>
              <!-- <div style="display: flex;align-items: center;">
                <div class="label">所在项目：</div>
                <Cascader :options="optionsCascader" :props="props" placeholder="请选择所在项目"></Cascader>
              </div> -->
              <div style="display: flex;align-items: center;">
                <div class="label">领取时间：</div>
                <DatePicker
                  type="daterange"
                  placement="bottom-end"
                  placeholder="选择时间"
                  style="width: 200px; margin-right: 14px"
                  @on-change="redPacketTime"
                  v-model="redPacketTimeData"
                  :editable="false"
                />
              </div>
              <Button
                type="primary"
                style="margin-right: 14px"
                @click="onSearchRedPacket"
                >搜索</Button
              >
              <Button @click="resetSearchRedPacket">重置</Button>
            </div>
            <Table
              highlight-row
              ref="packetwaterProject"
              :columns="packetWaterProjectCloumsList"
              :data="packetWaterProjectDataList"
              :loading="packetWaterProjectLoading"
              style="margin:10px 0 5px"
            >
              <!-- @on-current-change="handlepacketWaterTablePick" -->
              <template slot-scope="{ row, index }" slot="index">
                <span>{{index+1}}</span>
              </template>
              <template slot-scope="{ row }" slot="codeType">
                <span v-if="row.codeType==1">绿码</span>
                <span v-else-if="row.codeType==2">黄码</span>
                <span v-else-if="row.codeType==3">红码</span>
              </template>
              <template slot-scope="{ row }" slot="redPacketAmount">
                <span>{{row.redPacketAmount/100}}</span>
              </template>
            </Table>
            <div class="fun-page-box">
              <page
                show-total
                :total="packetWaterProjectPageData.total"
                :page-size="packetWaterProjectPageData.size"
                :current="packetWaterProjectPageData.current"
                @on-change="packetWaterProjectPageChange"
              />
                <!-- :total="packetWaterProjectPageData.total"
                :page-size="packetWaterProjectPageData.size"
                @on-change="packetWaterProjectPageChange"
                this.blackListPage = e;
      this.getBlackList();
                :current="packetWaterProjectPageData.current -->
            </div>
          </div>
        <!-- </Card> -->
      </div>
      <div v-if="inFlag == 16" class="toolBox">
        <div>
          <number-card />
        </div>
      </div>
      <div v-if="inFlag == 17" class="toolBox">
        <div>
          <look-face />
        </div>
      </div>
    </div>
    <Modal
      v-model="pawFlag"
      :loading="toNext"
      :mask-closable="false"
      title="请输入授权码"
      @on-ok="toPaw(1)"
      @on-cancel="toPaw(2)"
    >
      <Input v-model="password_t" type="password" placeholder="输入授权码" style />
    </Modal>
  </div>
</template>
<script>
import WorkTypeMan from './workType'
import numberCard from './numberCard'
import lookFace from './lookFace'
export default {
  components: {
    WorkTypeMan,
    numberCard,
    lookFace,
  },
  data() {
    return {
      toNext: true,
      inFlag: 1,
      inText: "",
      changePhone: "",
      password_t: "",
      pawFlag: false,
      userList: [],
      changeOldPhone: "",
      changeNewName: "",
      changeNewPhone: "",
      getVideoListLoading: false,
      getVideoListPage: 1,
      getVideoListTotal: 0,
      getVideoListData: [],
      videoSearchName: "",
      videoSearchPhone: "",
      columns1: [
        {
          title: "时间",
          key: "id",
          width: "90",
        },
        {
          title: "数据库数量",
          key: "dbCount",
        },
        {
          title: "redis缓存数量",
          key: "redisCount",
        },
        {
          title: "当日请求人数",
          key: "requestTimes",
        },
        {
          title: "创建时间",
          key: "createTime",
          width: "90",
        },
      ],
      data1: [],
      columns2: [
        {
          title: "分析数据日期",
          key: "statDate",
          width: "100",
        },
        {
          title: "观看用户记录表总数",
          key: "recordCount",
        },
        {
          title: "观看用户分析表总数",
          key: "statCount",
        },
      ],
      data2: [],
      apiCode: 0,
      apiStatus: "正在获取..",
      getWatchStatPage: 1,
      getWatchStatTotal: 0,
      getWatchStatLoading: false,
      userColumns: [],
      userDataList: [],
      userColumns1: [
        {
          title: "用户id",
          key: "id",
        },
        {
          title: "用户名称",
          key: "realname",
        },
        {
          title: "手机号",
          key: "phone",
        },
        {
          title: "实名时间",
          key: "realnameTimeStr",
        },
        {
          title: "操作",
          slot: "action",
          align: "center",
        },
      ],
      userColumns2: [
        {
          title: "用户id",
          key: "userId",
        },
        {
          title: "用户名称",
          key: "realname",
        },
        {
          title: "手机号",
          key: "phone",
        },
        {
          title: "班组名称",
          key: "vteam_name",
        },
         {
          title: "企业名称",
          key: "vcorp_name",
        },
        {
          title: "操作",
          slot: "action",
          align: "center",
        },
      ],
      userColumns3: [
        {
          title: "用户id",
          key: "iteam_id",
        },
        {
          title: "用户名称",
          key: "realname",
        },
        {
          title: "手机号",
          key: "phone",
        },
        {
          title: "班组名称",
          key: "vteam_name",
        },
        {
          title: "操作",
          slot: "action",
          align: "center",
        },
      ],
      userColumns4: [
        {
          title: "用户id",
          key: "iteam_id",
        },
        {
          title: "用户名称",
          key: "realname",
        },
        {
          title: "手机号",
          key: "phone",
        },
        {
          title: "班组名称",
          key: "vteam_name",
        },
        {
          title: "操作",
          slot: "action",
          align: "center",
        },
      ],
      userColumns5: [
        {
          title: "用户id",
          key: "userId",
        },
        {
          title: "用户名称",
          key: "realname",
        },
        {
          title: "手机号",
          key: "phone",
        },
        {
          title: "班组名称",
          key: "teamName",
        },
        {
          title: "项目名称",
          key: "projectName",
        },
        {
          title: "设备序列号",
          key: "serialNo",
        },
        {
          title: "设备状态",
          slot: "status",
        },
        {
          title: "操作",
          slot: "action",
          align: "center",
        },
      ],
      userColumns6: [
        {
          title: "考勤机号码",
          key: "serialNo",
        },
        {
          title: "班组名称",
          key: "teamName",
        },
        {
          title: "班组总人数",
          key: "totalCount",
        },
        {
          title: "完成任务人数",
          key: "finishTaskCount",
        },
        {
          title: "下发人数",
          key: "issueCount",
        },
        {
          title: "状态",
          key: "statusStr",
        },
        {
          title: "操作",
          slot: "action",
          align: "center",
        },
      ],
      userData1: [],
      userData2: [],
      userData3: [],
      userData4: [],
      userData5: [],
      userData6: [],
      d: [
        {
          title: "已实名未加班组",
          key: 1
        },
        {
          title: "已加入班组但无新手任务",
          key: 2
        },
        {
          title: "完成新手任务但无入场必备任务",
          key: 3
        },
        {
          title: "完成入场必备任务无三级安全培训任务",
          key: 4
        },
        {
          title: "完成入场必备无下发考勤机人员",
          key: 5
        },
        {
          title: "考勤机运行状态监控",
          key: 6
        },
      ],
      currentUserList: {
        title: "已实名未加班组",
        key: 1
      },
      getUserListLoading: false,
      getUserPage: 1,
      getUserSize: 15,
      getUserTotal: 0,
      getSignPage: 1,
      getSignSize: 20,
      getSignTotal: 0,
      signList: [],
      messageList: [],
      getMessagePage: 1,
      getMessageSize: 20,
      getMessageTotal: 0,
      subcList: [],
      getSubcSearch: '',
      subcontractProject1: {},
      subcontractProject2: {},
      getSubcLoading: false,
      editSubcList: [],
      getEditSubcLoading: false,
      editSubcPage: 1,
      editSubcSIze: 15,
      editSubcTotal: 0,
      subcListPage: 1,
      subcListSize: 15,
      subcListTotal: 0,
      // 红包配置
      userColumns14: [
        {
          title: "序号",
          key: "serialNo",
        },
        {
          title: "时间（周）",
          key: "ruleName",
          align: "center",
        },
        {
          title: "金额（元）",
          slot: "rewardAmount",
          align: "center",
        },
        {
          title: "操作",
          slot: "action",
          align: "center",
        },
      ],
      redPacketDataList: [],
      redPacketData: {
        totalRewardAmount: 0,
        redPacketStatus: false,
        releaseDate: "",
        redPacketTitle: "",
        redPacketCoverUrl: "",
        rules: [],
        // releaseDatecheckList: [], // 周几 多选框
      },
      dialogVisible: false,
      redPacketConfigModal: false,
      redPacketImages: [],
      dialogImageUrl: "",
      redPacketFile: "",
      props: {
        checkStrictly: true,
        lazy: true,
        lazyLoad: this.lazyLoad,
        // changeOnSelect:{
        //   default:false
        // },
      },
      getRedPacketStatLoading: false,
      redPacketModelLoading: false,
      labourCorpList: [],
      redConfigModal: {
        time: "", // 时间
        rewardAmount: 0, // 金额
        type: "edit", // 类型
      },
      // 红包流水
      projectData: "",
      redProjectId: undefined,
      redPacketTimeData: [],
      packetWaterProjectCloumsList: [
        {
          title: "序号",
          slot: "index",
          width: "50",
        },
        {
          title: "姓名",
          key: "realname",
          align: "center"
        },
        {
          title: "所属班组",
          key: "teamName",
          align: "center"
        },
        {
          title: "工种",
          key: "workTypeName",
          align: "center"
        },
        {
          title: "身份证",
          key: "idCardNumber",
          width: "170",
          align: "center"
        },
        {
          title: "教育码",
          slot: "codeType",
          align: "center"
        },
        {
          title: "领取金额（元）",
          slot: "redPacketAmount",
          align: "center"
        },
        {
          title: "领取时间",
          key: "receivedTime",
          width: "150",
          align: "center"
        }
      ],
      packetWaterProjectDataList: [
        // {
        //   realname: 'll',
        //   teamName: 'll',
        //   workTypeName: 'll',
        //   idCardNumber: 'll',
        //   codeType: 2,
        //   redPacketAmount: 15200,
        //   receivedTime: "2021-11-11 10:10:11"
        // }
      ],
      packetWaterProjectLoading: false,
      packetWaterProjectPageData:{
        total: 0,
        size: 10,
        current: 1,
      },
      optionsCascader: [
        {
          value: 'zhinan',
          label: '指南',
          children: [{
            value: 'shejiyuanze',
            label: '设计原则',
            children: [{
              value: 'yizhi',
              label: '一致'
            }, {
              value: 'fankui',
              label: '反馈'
            }, {
              value: 'xiaolv',
              label: '效率'
            }, {
              value: 'kekong',
              label: '可控'
            }]
          }]
        }
      ],
      packetWaterProjectSearch: {
        keyword: undefined,
        startDate: undefined,
        endDate: undefined,
      },
      redPacketStatData: {
        redPacketTotalAmount: 0, // 已发放红包总额
        redPacketTotalNumber: 0, // 已发放红包个数
        receivedTotalAmount: 0, // 已领取红包总额
        receivedTotaNumber: 0, // 已领取红包个数
      }, // 红包统计数据
    };
  },
  filters: {},
  created() {},
  mounted() {
    // 测试
    this.pawFlag = true;
    // setInterval(function() {
    //   document.querySelectorAll(".el-cascader-node__label").forEach(el => {
    //     el.onclick = function() {
    //       if (this.previousElementSibling) this.previousElementSibling.click();
    //     };
    //   });
    // }, 800);
  },
  destory() {},
  methods: {
    lazyLoad(node, resolve) {
      setTimeout(() => {
        if(node.level==0){
          this.getCompanyDataList(node, resolve)
          console.log(111111111111111,node,resolve)
        }else if(node.level==1){
          this.getProjectDataList(node, resolve)
          node.loading=false;
          node.loaded=true;
        }else{
          node.loading=false;
          node.loaded=true;
        }
      }, 1000)
    },
    // 获取公司列表
    getCompanyDataList(node, resolve) {
      this.$axios
        .get(`${this.apiUrlCros}/sapi/corp/superadmin/list?pageNo=1&pageSize=100`)
        .then(res=>{
          if(res.data.code=="200") {
            const companyData = res.data.data.records.map((value, i) => ({
              value: value.id,
              label: value.vname,
              leaf: node.level >= 1
            }));
            resolve(companyData)
          }
        })
    },
    // 获取项目列表
    getProjectDataList(node, resolve) {
      this.$axios
        .get(`${this.apiUrlCros}/sapi/project/superadmin/list?pageNo=1&pageSize=100&corpId=${node.value}`)
        .then(res=>{
          if(res.data.code=="200") {
            const projectData = res.data.data.records.map((value, i) => ({
              value: value.id,
              label: value.name,
              children: undefined,
              leaf: node.level >= 0
            }));
            node.loading=false;
            node.loaded=true;
            resolve(projectData)
          }
        })
    },
    // 监听
    projectChange(info) {
      if(info.length==1){
        this.redProjectId = undefined
        return this.$Message.error("请选择项目");
      }
      this.redProjectId = info[1]
      this.getRedPacketStatData(info[1])
      this.getRedPacketWaterData(info[1])
    },
    async toApply(aid, id) {
      let res = await this.$fetch(
        `/sapi/devOps/applyUser?password=${this.password_t}`,
        {
          method: "post",
          data: {
            auditStatus: aid,
            ids: id,
          },
        }
      );
      if (res) {
        if (res.code == 200) {
          this.$Message.success("审核成功");
          this.getUserList();
        } else {
          this.$Message.error("审核失败");
        }
      }
    },
    toApplySign(id){
      let data = {
        projectId: id
      };
      this.$fetch("/sapi/project/auditPaySign", {
        method: "post",
        data,
      }).then((res) => {
        if (res.code == "200") {
          this.getSignPage = 1,
          this.getSignList()
          this.$Message.info("操作成功");
        } else {
          this.$Message.info("操作失败，请稍后再试");
        }
      });
    },
    toSendMsg(id){
      let data = {
        projectId: id
      };
      this.$fetch("/sapi/projectSms/sendSmsByProjectId", {
        method: "post",
        data,
      }).then((res) => {
        if (res.code == "200") {
          this.getMessagePage = 1
          this.getMessageList();
          this.$Message.info("操作成功");
        } else {
          this.$Message.info("操作失败，请稍后再试");
        }
      });
    },
    toUpdateMsg(id,status){
      let data = {
        projectId: id,
        isRemoved: status
      };
      this.$fetch("/sapi/projectSms/update", {
        method: "post",
        data,
      }).then((res) => {
        if (res.code == "200") {
          this.getMessagePage = 1
          this.getMessageList();
          this.$Message.info("操作成功");
        } else {
          this.$Message.info("操作失败，请稍后再试");
        }
      });
    },
    toPaw(e) {
      if (e == 1) {
        if (this.password_t.trim() == "xt_19940602") {
          this.pawFlag = false;
        } else {
          // this.$router.push({ path: "/home" });
          this.$Message.error("密码错误");
          this.password_t = "";
          this.toNext = false;
          setTimeout(() => {
            this.toNext = true;
          }, 100);
          return false;
        }
      } else if (e == 2) {
        this.$router.push({ path: "/home" });
      }
    },
    pickTool(e) {
      this.inFlag = e;
      if (e == 2) {
        this.inText = "重置密码";
      } else if (e == 3) {
        this.inText = "企业审批";
        this.getUserList();
      } else if (e == 4) {
        this.inText = "更改企业账号";
      } else if (e == 5) {
        this.inText = "任务审核";
        this.getVideoListPage = 1;
        this.getVideoListLoading = true;
        this.getVideoList();
      } else if (e == 6) {
        this.inText = "排行榜数据监控";
        this.toGetDataMonitoring();
      } else if (e == 7) {
        this.inText = "我的学习数据监控";
        this.apiCode = 0;
        this.apiStatus = "正在获取..";
        this.getWatchStatPage = 1;
        this.getWatchStatLoading = true;
        this.toGetWatchStat();
      } else if (e == 8) {
        this.inText = "人员数据监控";
        this.userColumns = this.userColumns1
        this.getUserPage = 1
        this.getUserListLoading = true
        this.getUserListData1()
        this.getUserListData2()
        this.getUserListData3()
        this.getUserListData4()
        this.getUserListData5()
      }else if (e == 9){
        this.inText = "电子签审批";
        this.getSignPage = 1
        this.getSignList();
      }else if (e == 10){
        this.inText = "短信监控";
        this.getMessagePage = 1
        this.getMessageList();
      }else if (e == 11){
        this.inText = "设置总分包项目";
        this.getSubcSearch = ''
        this.subcListPage = 1
        this.getSubcLoading = true
        this.getSubcList();
      }else if (e == 12){
        this.inText = "修改总分包项目";
        this.getEditSubcLoading = true
        this.editSubcPage = 1
        this.getEditSubcList();
      }else if (e == 13){
        this.inText = "工种管理";
      }else if (e == 14){
        this.inText = "红包配置";
        this.redPacketImages = []
        this.getRedPacketConfigData()
      }else if (e == 15){
        this.inText = "红包流水";
        this.packetWaterProjectSearch.keyword = ""
        this.packetWaterProjectSearch.startDate = ""
        this.packetWaterProjectSearch.endDate = ""
        this.redPacketTimeData = []
        this.redProjectId = ""
        this.packetWaterProjectPageData.current = 1
        this.getRedPacketWaterData(this.redProjectId)
        this.getRedPacketStatData(this.redProjectId)
      }else if ( e == 16) {
        this.inText = "数字证书开关配置"
      }else if ( e == 17) {
        this.inText = "人脸识别切换"
      }
    },
    backIndex() {
      this.inFlag = 1;
    },
    async toChangeMan() {
      console.log(this.password_t);
      if (this.changeOldPhone && this.changeNewName && this.changeNewPhone) {
        // let res = await this.$fetch(
        //   `/sapi/devOps/changeManager?password=${this.password_t}`,
        //   {
        //     method: "post",
        //     data: {
        //       newName: this.changeNewName,
        //       newPhone: this.changeNewPhone,
        //       oldPhone: this.changeOldPhone,
        //     },
        //   }
        // );
        let data = {
          newName: this.changeNewName,
          newPhone: this.changeNewPhone,
          oldPhone: this.changeOldPhone,
        }
        let res = await this.$axios.post(`https://api.dougongapp.com/dgtech/api/mgmt/corp/manager/change?password=${this.password_t}`,{
          // let res = await this.$axios.post(`/dgtech/api/mgmt/corp/manager/change?password=${this.password_t}`,{
          newName: this.changeNewName,
          newPhone: this.changeNewPhone,
          oldPhone: this.changeOldPhone,
        })
        if (res) {
          if (res.data.resultStatus) {
            this.$Message.success("修改成功");
          } else {
            this.$Message.error("修改失败");
          }
        }
      }
    },
    toChangePassword() {
      if (!this.changePhone) {
        return false;
      }
      let downUrl = ''
      // downUrl = this.common.openUrl('8082','sapi',`/devOps/resetPassword?phone=${this.changePhone}&password=${this.password_t}`);
      downUrl = `/sapi/devOps/resetPassword?phone=${this.changePhone}&password=${this.password_t}`;
      // window.open(downUrl, "_blank");
      this.$axios
        .get(`${this.apiUrlCros}`+downUrl)
        .then((res) => {
          if (res.data.code == "200") {
            this.$Message.success('重置成功')
          } else {
            this.$Message.error("操作失败，稍后再试");
          }
          // console.log(res)
        })
        .catch((err) => {
         // this.$Message.error(err);
        });
    },
    async getUserList() {
      let res = await this.$fetch(
        `/sapi/devOps/userList?password=${this.password_t}`,
        {
          method: "post",
          data: {
            auditStatus: 0,
          },
        }
      );
      if (res) {
        let list = res.data;
        this.userList = [];
        this.userList = list;
      }
    },
    getSignList(){
      let data = {
        pageNumber: this.getSignPage,
        pageSize: this.getSignSize,
        vprojectcode: "",
        vprojectname: ""
      };
      this.$fetch("/sapi/project/getPaySignProjects", {
        method: "post",
        data,
      }).then((res) => {
        console.log(res);
        if (res.code == "200") {
          // this.$Message.success(res.msg || "新增考勤机成功");
          // // 处理修改成功
          // this.modalFlag.addEquipment = false;
          // this.checkInRelateTableLoading = true;
          // this.newEquipmentData = { eId: "", eName: "" };
          // this.getTeamCheckinRelateList();
          this.signList = res.resList;
          this.getSignTotal = res.total;
        } else {
          this.$Message.info("获取电子签审核列表失败");
        }
      });
    },
    getMessageList(){
      let data = {
        pageNumber: this.getMessagePage,
        pageSize: this.getMessageSize
      };
      this.$fetch("/sapi/projectSms/getByPage", {
        method: "post",
        data,
      }).then((res) => {
        console.log(res);
        if (res.code == "200") {
          // this.$Message.success(res.msg || "新增考勤机成功");
          // // 处理修改成功
          // this.modalFlag.addEquipment = false;
          // this.checkInRelateTableLoading = true;
          // this.newEquipmentData = { eId: "", eName: "" };
          // this.getTeamCheckinRelateList();
          this.messageList = res.resList;
          this.getMessageTotal = res.total;
        } else {
          this.$Message.info("获取短信监控列表失败");
        }
      });
    },
    delEditSubcList(val){
      let data = {
        projectId: val.projectId,
        pid: val.pid,
        corpId: val.subContractCorpId,
        isRemoved: "1"
      }
      this.$fetch("/sapi/turnkey/setTurkeySubContract", {
        method: "post",
        data: data
      }).then((res) => {
        if(res.code == "200"){
          this.$Message.success("操作成功")
          this.getEditSubcLoading = true
          this.getEditSubcList()
        }else{
          this.$Message.error("操作失败")
        }
      }).catch(err => {
      });
    },
    handelEditSubcListChange(e){
      this.editSubcPage = e
      this.getEditSubcLoading = true
      this.getEditSubcList()
    },
    handelSubcListChange(e){
      this.subcListPage = e
      this.getSubcLoading = true
      this.getSubcList()
    },
    getEditSubcList(){
      let data = {
        pageNumber: this.editSubcPage,
        pageSize: this.editSubcSIze
      };
      this.$fetch("/sapi/turnkey/getByPage", {
        method: "post",
        data,
      }).then((res) => {
        console.log(res);
        if (res.code == "200") {
          this.getEditSubcLoading = false
          this.editSubcList = res.resList;
          this.editSubcTotal = res.total
        } else {
          this.$Message.info("获取列表失败");
        }
      });
    },
    getSubcList(){
      let data = {
        vprojectname: this.getSubcSearch,
        pageNumber: this.subcListPage,
        pageSize: this.subcListSize
      };
      this.$fetch("/sapi/project/searchProject", {
        method: "post",
        data,
      }).then((res) => {
        console.log(res);
        if (res.code == "200") {
          this.getSubcLoading = false
          this.subcListTotal = res.total
          this.subcList = res.resList;
        } else {
          this.$Message.info("获取列表失败");
        }
      });
    },
    searchSubc(){
      this.getSubcLoading = true
      this.getSubcList()
    },
    resetSearchSubc(){
      this.getSubcLoading = true
      this.getSubcSearch = ""
      this.getSubcList()
    },
    getVideoList() {
      let data = {
        pageNumber: this.getVideoListPage,
        pageSize: "10",
        name: this.videoSearchName,
        phone: this.videoSearchPhone,
      };
      this.$fetch("/sapi/task/getAuditList", {
        method: "post",
        data,
      }).then((res) => {
        console.log(res);
        if (res.code == "200") {
          // this.$Message.success(res.msg || "新增考勤机成功");
          // // 处理修改成功
          // this.modalFlag.addEquipment = false;
          // this.checkInRelateTableLoading = true;
          // this.newEquipmentData = { eId: "", eName: "" };
          // this.getTeamCheckinRelateList();
          this.getVideoListData = res.data;
          this.getVideoListTotal = res.total;
          this.getVideoListLoading = false;
        } else {
          this.$Message.info("获取审核列表失败");
          this.getVideoListLoading = false;
          // this.modalFlag.addEquipment = false;
          // this.checkInRelateTableLoading = true;
          // this.newEquipmentData = { eId: "", eName: "" };
          // this.getTeamCheckinRelateList();
        }
      });
    },
    handelGetVideoPageChange(e) {
      this.getVideoListLoading = true;
      this.getVideoListPage = e;
      this.getVideoList();
    },
    toSearchVideo() {
      this.getVideoListLoading = true;
      this.getVideoList();
    },
    toResetSearchVideo() {
      this.videoSearchName = "";
      this.videoSearchPhone = "";
      this.getVideoListPage = 1;
      this.getVideoListLoading = true;
      this.getVideoList();
    },
    toPastVideo(val, type) {
      console.log(val);
      if (val.userId == "" || val.taskId == "" || val.teamId == "") {
        this.$Message.info("该数据错误，缺少userId、taskId或teamId");
        return false;
      }
      let data = {
        userId: val.userId,
        taskId: val.taskId,
        teamId: val.teamId,
        status: type,
      };
      this.$fetch("/sapi/task/approveAudit", {
        method: "post",
        data,
      }).then((res) => {
        console.log(res);
        if (res.code == "200") {
          this.$Message.info("审核成功");
          this.toResetSearchVideo();
        } else {
          this.$Message.info("审核失败");
        }
      });
    },
    toGetDataMonitoring() {
      // 获取接口状态
      // http://134.175.132.191:8082/api/rankList/findByDate
      this.getApiStatus();
      this.getApiStatusData();
    },
    async getApiStatus() {
      let time = new Date().getTime() - 24 * 60 * 60 * 1000;
      let yesterday = new Date(time);
      let month = yesterday.getMonth();
      let day = yesterday.getDate();
      yesterday =
        yesterday.getFullYear() +
        "-" +
        (yesterday.getMonth() > 9
          ? yesterday.getMonth() + 1
          : "0" + (yesterday.getMonth() + 1)) +
        "-" +
        (yesterday.getDate() > 9
          ? yesterday.getDate()
          : "0" + yesterday.getDate());

      let res = await this.$fetch(
        `/sapi/rankList/findByDate?date=${yesterday}`
      );
      if (res) {
        this.apiCode = res.code;
        if (res.code == "200") {
          this.apiStatus = "正常";
        } else {
          this.apiStatus = "接口异常";
        }
      } else {
        this.apiStatus = "获取失败";
      }
    },
    async getApiStatusData() {
      let res = await this.$fetch(`/sapi/rankMonitor/getRankMonitors`);
      if (res && res.code == "200") {
        this.data1 = res.data;
      } else {
        this.$Message.error("获取排行榜监控数据失败");
      }
    },
    toGetWatchStat() {
      this.getApiStatus();
      let data = {
        pageNumber: this.getWatchStatPage,
        pageSize: "10",
      };
      this.$fetch("/sapi/stat/getWatchStat", {
        method: "post",
        data,
      }).then((res) => {
        console.log(res);
        if (res.code == "200") {
          this.data2 = res.data;
          this.getWatchStatTotal = res.total;
          this.getWatchStatLoading = false;
        } else {
          this.$Message.info("获取学习中心数据监控接口失败");
          this.getWatchStatLoading = false;
        }
      });
    },
    handelGetWatchStatChange(e) {
      this.getWatchStatPage = e;
      this.getWatchStatLoading = true;
      this.toGetWatchStat();
    },
    pickUserList(val){
      this.currentUserList = val
      this.getUserPage = 1
      this.getUserListLoading = true
      if ( val.key == 1){
        this.userColumns = this.userColumns1
        this.userDataList = this.userData1
      this.getUserListData1()
      }else if( val.key == 2){
        this.userColumns = this.userColumns2
        this.userDataList = this.userData2
      this.getUserListData2()
      }else if( val.key == 3){
        this.userColumns = this.userColumns3
        this.userDataList = this.userData3
      this.getUserListData3()
      }else if( val.key == 4){
        this.userColumns = this.userColumns4
        this.userDataList = this.userData4
      this.getUserListData4()
      }else if( val.key == 5){
        this.userColumns = this.userColumns5
        this.userDataList = this.userData5
      this.getUserListData5()
      }else if( val.key == 6){
        this.userColumns = this.userColumns6
        this.userDataList = this.userData6
      this.getUserListData6()
      }
    },
    handelGetUserListChange(e){
      this.getUserPage = e
      let val = this.currentUserList
      this.getUserListLoading = true
      if ( val.key == 1){
        this.userColumns = this.userColumns1
        this.userDataList = this.userData1
      this.getUserListData1()
      }else if( val.key == 2){
        this.userColumns = this.userColumns2
        this.userDataList = this.userData2
      this.getUserListData2()
      }else if( val.key == 3){
        this.userColumns = this.userColumns3
        this.userDataList = this.userData3
      this.getUserListData3()
      }else if( val.key == 4){
        this.userColumns = this.userColumns4
        this.userDataList = this.userData4
      this.getUserListData4()
      }else if( val.key == 5){
        this.userColumns = this.userColumns5
        this.userDataList = this.userData5
      this.getUserListData5()
      }else if( val.key == 6){
        this.userColumns = this.userColumns6
        this.userDataList = this.userData6
      this.getUserListData6()
      }
    },
    getUserListData1(){
      var that = this
      this.$axios.get(`${this.apiUrlCros}/sapi/devOps/findNotJoinTeam?pageNumber=${this.getUserPage}&pageSize=${this.getUserSize}`).then(res => {
        if (res.data.code == "200") {
          that.userData1 = res.data.data
          if(that.currentUserList.key == 1){
            that.getUserTotal = res.data.total
            that.getUserListLoading = false
            that.userDataList = res.data.data
          }
        } else {
          that.$Message.error("获取项目统计数据失败");
        }
        console.log(res)
      }).catch(err => {
        this.$Message.error(err.message);
      })
    },
    getUserListData2(){
      var that = this
      this.$axios.get(`${this.apiUrlCros}/sapi/task/without/newbie?pageNum=${this.getUserPage}&pageSize=${this.getUserSize}`).then(res => {
        if (res.data.code == "200") {
          that.userData2 = res.data.data.records
          if(that.currentUserList.key == 2){
            that.getUserTotal = res.data.data.total
            that.getUserListLoading = false
            that.userDataList = res.data.data.records
          }
        } else {
          that.$Message.error("获取项目统计数据失败");
        }
        console.log(res)
      }).catch(err => {
        this.$Message.error(err.message);
      })
    },
    getUserListData3(){
      var that = this
      this.$axios.get(`${this.apiUrlCros}/sapi/task/no/entrance/task?pageNum=${this.getUserPage}&pageSize=${this.getUserSize}`).then(res => {
        if (res.data.code == "200") {
          that.userData3 = res.data.data.records
          if(that.currentUserList.key == 3){
            that.getUserTotal = res.data.data.total
            that.getUserListLoading = false
            that.userDataList = res.data.data.records
          }
        } else {
          that.$Message.error("获取项目统计数据失败");
        }
        console.log(res)
      }).catch(err => {
        this.$Message.error(err.message);
      })
    },
    getUserListData4(){
      var that = this
      this.$axios.get(`${this.apiUrlCros}/sapi/task/no/three/task?pageNum=${this.getUserPage}&pageSize=${this.getUserSize}`).then(res => {
        if (res.data.code == "200") {
          that.userData4 = res.data.data.records
          if(that.currentUserList.key == 4){
            that.getUserTotal = res.data.data.total
            that.getUserListLoading = false
            that.userDataList = res.data.data.records
          }
        } else {
          that.$Message.error("获取项目统计数据失败");
        }
        console.log(res)
      }).catch(err => {
        this.$Message.error(err.message);
      })
    },
    getUserListData5(){
      var that = this
      this.$axios.get(`${this.apiUrlCros}/sapi/devOps/findFinishInNotIssue?pageNumber=${this.getUserPage}&pageSize=${this.getUserSize}`).then(res => {
        if (res.data.code == "200") {
          that.userData5 = res.data.data
          if(that.currentUserList.key == 5){
            that.getUserTotal = res.data.total
            that.getUserListLoading = false
            that.userDataList = res.data.data
          }
        } else {
          that.$Message.error("获取项目统计数据失败");
        }
        console.log(res)
      }).catch(err => {
        this.$Message.error(err.message);
      })
    },
    getUserListData6(){
      var that = this
      this.$axios.get(`${this.apiUrlCros}/sapi/devOps/teamIssueInfo?pageNumber=${this.getUserPage}&pageSize=${this.getUserSize}`).then(res => {
        if (res.data.code == "200") {
          that.userData6 = res.data.data
          if(that.currentUserList.key == 6){
            that.getUserTotal = res.data.total
            that.getUserListLoading = false
            that.userDataList = res.data.data
          }
        } else {
          that.$Message.error("获取项目统计数据失败");
        }
        console.log(res)
      }).catch(err => {
        this.$Message.error(err.message);
      })
    },
    toIssuePersons(val){
      let data = {
        realname: val.realname,
        vteamCode: val.teamCode
      }
      this.$fetch("/sapi/teamCheckinRelate/issuePersons", {
        method: "post",
        data: [data]
      }).then((res) => {
        if(res.code == "200"){
          this.$Message.success("下发成功")
          this.pickUserList({
            title: "完成入场必备无下发人员",
            key: 5
          })
        }else{
          this.$Message.error("下发失败")
        }
      }).catch(err => {
      });
    },
    toOpenImg(val){
      
      window.open(val.faceidImage,'_blank')
    },
    toSendTask(val){
      console.log(val)
      let data = {}
      let that = this
      this.$fetch(`/sapi/task/newbie/task/send?phone=${val.phone}`, {
        method: "post",
        data
      }).then((res) => {
        if(res.code == "200"){
          this.$Message.success("下发成功")
          if(that.currentUserList.key == 2){
            this.pickUserList({
              title: "已加入班组但无新手任务",
              key: 2
            })
          }
          if(that.currentUserList.key == 3){
            this.pickUserList({
              title: "完成新手任务但无入场必备任务",
              key: 3
            })
          }
        }else{
          this.$Message.error("下发失败")
        }
      }).catch(err => {
      });
    },
    toSendTask1(val){
      console.log(val)
      let data = {}
      let that = this
      this.$fetch(`/sapi/task/reissue?phone=${val.phone}&teamId=${val.iteam_id}`, {
        method: "post",
        data: data
      }).then((res) => {
        if(res.code == "200"){
          this.$Message.success("下发成功")
          if(that.currentUserList.key == 2){
            this.pickUserList({
              title: "在班组无班组任务",
              key: 2
            })
          }
          if(that.currentUserList.key == 3){
            this.pickUserList({
              title: "完成入场必备无班组任务",
              key: 3
            })
          }
        }else{
          this.$Message.error("下发失败")
        }
      }).catch(err => {
      });
    },
    handelGetSignListChange(e){
      this.getSignPage = e
      this.getSignList()
    },
    handelGetMessageListChange(e){
      this.getMessagePage = e
      this.getMessageList()
    },
    setProject(val,type){
      if(type == 1){
        this.subcontractProject1 = val
      }else if(type == 2){
        this.subcontractProject2 = val
      }
    },
    delSubc(type){
      if(type == 1){
        this.subcontractProject1 = {}
      }else if(type == 2){
        this.subcontractProject2 = {}
      }
    },
    toSetSubc(){
      if(this.subcontractProject1.id == this.subcontractProject2.id){
        this.$Message.error("不要选择相同项目")
      }else{
        let data = {
        projectId: this.subcontractProject2.id,
        pid:  this.subcontractProject1.id,
        corpId:  this.subcontractProject2.icorpid,
        isRemoved: 0
      }
      let that = this
      this.$fetch("/sapi/turnkey/setTurkeySubContract", {
        method: "post",
        data: data
      }).then((res) => {
        if(res.code == "200"){
          that.$Message.success("操作成功")
          that.subcontractProject2 = {}
          that.subcontractProject1 = {}
          that.getSubcSearch = ""
          that.getSubcLoading = true
          that.getSubcList()
        }else{
          that.$Message.error("操作失败")
        }
      }).catch(err => {
      });
      }
    },
    toUpdateCheckStatus(val){
      let data = {
        teamId: val.teamId
      }
      let that = this
      this.$fetch("/sapi/devOps/updateMachineStatus", {
        method: "post",
        data: data
      }).then((res) => {
        if(res.code == "200"){
         this.$Message.success("更新成功")
          this.pickUserList({
            title: "考勤机运行状态监控",
            key: 6
          })
        }else{
          this.$Message.error("更新失败")
        }
      }).catch(err => {
      });
    },
    toUpdateCheckStatuss(){
      let data = {}
      let that = this
      this.$fetch("/sapi/devOps/updateBatchMachineStatus", {
        method: "post",
        data: data
      }).then((res) => {
        if(res.code == "200"){
          this.$Message.success("更新成功")
          this.pickUserList({
            title: "考勤机运行状态监控",
            key: 6
          })
        }else{
          this.$Message.error("更新失败")
        }
      }).catch(err => {
      });
    },
    // 预览红包
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    // 上传
    onBefore(file) {
      this.redPacketFile = file;
      this.senBlackList();
      return false;
    },
    senBlackList() {
      let formData = new FormData();
      formData.append("file", this.redPacketFile);
      this.$fetch("/osapi/common/uploadFile", {
        method: "post",
        data: formData,
        headers: {
          //添加请求头
          "Content-Type": "multipart/form-data;",
        },
      })
        .then((res) => {
          if (res.code == "200") {
            this.redPacketFile = null;
            this.$Message.success("上传成功");
            let obj = {
              url: res.data,
              name: "红包图片"
            }
            this.redPacketData.redPacketCoverUrl = res.data
            this.redPacketImages.push(obj)
          } else {
            this.redPacketFile = null;
            this.$Message.error("上传失败，稍后再试");
          }
        })
        .catch((err) => {
          // this.$Message.error(err);
          this.redPacketFile = null;
        });
    },
    // 上传前删除以前图片
    handleChange(file, fileList) {
      this.redPacketImages.pop();
    },
    // 查询红包模板配置
    getRedPacketConfigData() {
      this.$axios
        .get(`${this.apiUrlCros}/sapi/redpacket/config?configType=PLATFORM`)
        .then(res=>{
          if(res.data.code=='200') {
            Object.assign(this.redPacketData, res.data.data)
            let obj = {
              url: this.redPacketData.redPacketCoverUrl,
              name: "红包图片"
            }
            if(obj.url!="") {
              this.redPacketImages.push(obj)
              this.dialogImageUrl = this.redPacketData.redPacketCoverUrl
            }
            // this.redPacketData.releaseDatecheckList = res.data.data.releaseDate.split(',')
            this.redPacketData.totalRewardAmount = this.redPacketData.totalRewardAmount/100
            this.redPacketDataList = res.data.data.rules
          }
        })
    },
    // 新增/编辑红包
    editRedConfig(type, info, index) {
      this.cancelRedPacketMode()
      if(type=='edit') {
        this.redConfigModal = {
          time: info.ruleName, // 时间
          rewardAmount: info.rewardAmount/100, // 金额
          index
        }
      }
      this.redConfigModal.type = type
      this.redPacketConfigModal = true
    },
    // 新增编辑分配规则-红包-弹框-确认
    setRedPacketMode(){
      if(this.redConfigModal.rewardAmount == "") {
        this.$Message.error("请填写金额");
        this.cancelRedPacketMode()
        return
      }
      if(this.redConfigModal.time == "") {
        this.$Message.error("请填写时间");
        this.cancelRedPacketMode()
        return
      }
      if(this.redConfigModal.type=='add'){
        let obj = {
          rewardAmount: this.redConfigModal.rewardAmount*100,
          ruleName: this.redConfigModal.time,
          serialNo: this.redPacketDataList.length+1,
        }
        this.redPacketDataList.push(obj)
      }else{
        this.redPacketDataList[this.redConfigModal.index].rewardAmount=this.redConfigModal.rewardAmount*100
        this.redPacketDataList[this.redConfigModal.index].ruleName=this.redConfigModal.time
      }
    },
    // 新增编辑分配规则-红包-弹框-取消
    cancelRedPacketMode(){
      this.redConfigModal = {
        time: "", // 时间
        rewardAmount: 0, // 金额
        type: "edit", // 类型
      }
    },
    // 移除
    delRedConfig(info, index) {
      this.redPacketDataList.splice(index,1)
    },
    // 保存红包配置
    onSaveRedConfig() {
      let redPacketData = JSON.parse(JSON.stringify(this.redPacketData))
      let obj = {
        configType: "PLATFORM",
        totalRewardAmount: redPacketData.totalRewardAmount*100,
        redPacketStatus: redPacketData.redPacketStatus,
        releaseDate: redPacketData.releaseDate,
        redPacketTitle: redPacketData.redPacketTitle,
        redPacketCoverUrl: redPacketData.redPacketCoverUrl,
        rules: redPacketData.rules
      }
      let Amount = 0
      obj.rules.forEach(item=>{
        Amount += item.rewardAmount
      })
      if(Amount!=obj.totalRewardAmount) {
        return this.$Message.error("分配金额与人均支出金额不等")
      }
      this.$axios.post(`${this.apiUrlCros}/sapi/redpacket/config`, obj)
        .then(res => {
          if (res.data.code == "200") {
            this.redPacketImages = []
            this.getRedPacketConfigData()
            this.$Message.success("保存成功")
          } else {
            this.$Message.error(res.data.msg);
          }
        })
        .catch(err => {
          this.$Message.error(err.message);
        })
        .finally(()=>{
          obj = null
        })
    },
    // 获取项目红包统计数据 
    getRedPacketStatData(id) {
      var url = `${this.apiUrlCros}/sapi/redpacket/statistics/?projectId=${id}`
      if(id==""||id==undefined){
        url = `${this.apiUrlCros}/sapi/redpacket/statistics`
      }
      this.$axios
        .get(url)
        .then((res) => {
          if (res.data.code == "200") {
            Object.assign(this.redPacketStatData, res.data.data)
          } else {
            this.$Message.error("获取红包统计数据失败");
          }
        })
        .catch((err) => {
         // this.$Message.error(err.message);
        });
    },
    // 监听时间
    redPacketTime(time) {
      this.packetWaterProjectSearch.startDate = time[0]
      this.packetWaterProjectSearch.endDate = time[1]
    },
    // 查询
    onSearchRedPacket() {
      // console.log(this.redProjectId)
      // if(this.redProjectId==undefined) {
      //   this.$Message.error("请选择项目");
      // }else{
        this.getRedPacketWaterData(this.redProjectId)
        this.getRedPacketStatData(this.redProjectId)
      // }
    },
    // 数据页监听
    packetWaterProjectPageChange(e) {
      this.packetWaterProjectPageData.current = e;
      this.getRedPacketWaterData();
    },
    // 重置
    resetSearchRedPacket() {
      this.packetWaterProjectSearch.keyword = ""
      this.packetWaterProjectSearch.startDate = ""
      this.packetWaterProjectSearch.endDate = ""
      this.redPacketTimeData = []
      this.redProjectId = ""
      this.packetWaterProjectPageData.current = 1
      this.onSearchRedPacket()
    },
    // 获取红包流水数据
    getRedPacketWaterData(id) {
      let params = {
        projectId: id || "",
        keyword: this.packetWaterProjectSearch.keyword || "",
        startDate: this.packetWaterProjectSearch.startDate,
        endDate: this.packetWaterProjectSearch.endDate,
        pageNo: this.packetWaterProjectPageData.current,
        pageSize: this.packetWaterProjectPageData.size,
      }
      if(params.startDate==undefined||params.startDate==""){
        delete params.startDate
      }
      if(params.endDate==undefined||params.endDate==""){
        delete params.endDate
      }
      this.$axios({
        method: 'get',
        url: `${this.apiUrlCros}/sapi/redpacket/records`,
        params
      })
        .then((res) => {
          if (res.data.code == "200") {
            this.packetWaterProjectPageData.total = res.data.data.totalNum
            this.packetWaterProjectDataList = res.data.data.dataList
          } else {
            this.$Message.error("获取红包流水数据失败");
          }
        })
        .catch((err) => {
         // this.$Message.error(err.message);
        });
    },
  },
};
</script>
<style scoped>
.super >>> .ivu-table-cell {
  padding-right: 10px !important;
  padding-left: 10px !important;
}
</style>
<style scoped>
.el-cascader-panel .el-radio{
        width: 100% !important;
        height: 100% !important;
        z-index: 10 !important;
        position: absolute !important;
        top: 10px !important;
        right: 10px !important;
    }
    .el-cascader-panel .el-radio__input{
        visibility: hidden !important;
    }
    .el-cascader-panel .el-cascader-node__postfix {
        top: 10px !important;
    }
.demo-spin-icon-load {
  animation: ani-demo-spin 1s linear infinite;
}
@keyframes ani-demo-spin {
  from {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.demo-spin-col {
  height: 100px;
  position: relative;
  border: 1px solid #eee;
}
</style>
<style scoped lang="less">
* {
  box-sizing: border-box;
}

.pagebox {
  width: 100%;
  padding: 10px 20px;
}
.sec-title {
  margin-bottom: 6px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  .shu {
    width: 5px;
    height: 14px;
    background: #409eff;
    margin-right: 6px;
  }
}
.status-box {
  padding-left: 40px;
  padding-top: 10px;
  padding-bottom: 20px;
}
.super {
  max-width: 1200px;
  background: #fff;
  margin: 0 auto;
  .main {
    display: flex;
    justify-content: flex-start;
    // min-height: 100vh;
    flex-wrap: wrap;
    .tool-item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 160px;
      margin: 2px 5px;
      cursor: pointer;
      height: 150px;
      .icon1 {
        font-size: 32px;
        margin-bottom: 4px;
        color: #67c23a;
      }
      .icon2 {
        font-size: 32px;
        margin-bottom: 4px;
        color: #e6a23c;
      }
      .icon3 {
        font-size: 32px;
        margin-bottom: 4px;
        color: #f56c6c;
      }
      .icon4 {
        font-size: 32px;
        margin-bottom: 4px;
        color: #1296db;
      }
      .icon5 {
        font-size: 32px;
        margin-bottom: 4px;
        color: #e3f02c;
      }
    }
  }
  .userVideoBox {
    width: 100%;
    display: flex;
    flex-direction: column;
    .uv-li {
      display: flex;
      border-bottom: 1px solid #dcdee2;
      padding: 5px;
      margin-bottom: 5px;
      flex-wrap: wrap;
      justify-content: space-around;
      .userInfo {
        display: flex;
        flex-direction: column;
        justify-content: center;
        .info-item {
          display: flex;
          margin-bottom: 4px;
          .label {
            width: 70px;
          }
          .text {
            flex: 1;
          }
        }
      }
      .videoBox {
        display: flex;
        align-items: center;
        margin-right: 10px;
      }
      .vbtnbox {
        display: flex;
        align-items: center;
      }
    }
    .videoSearchBox {
      border-bottom: 1px solid #dcdfe6;
      .csb-item {
        display: flex;
        padding-left: 30px;
        margin-bottom: 20px;
        align-items: center;
        .label {
          width: 80px;
        }
      }
    }
  }
  .toolMain {
    width: 100%;
    display: flex;
    flex-direction: column;
    .toolHead {
      display: flex;
      align-items: center;
      margin-bottom: 30px;
      cursor: pointer;
    }
    .backIcon {
      font-size: 18px;
    }
    .headtitle {
      margin-left: 10px;
      font-weight: bold;
    }
    .toolBox {
      min-height: 100vh;
      .changeMan {
        display: flex;
        flex-direction: column;
        padding: 5px 10px;
      }
      .toolboxstyle {
        padding: 10px 8px;
        display: flex;
        flex-direction: column;
      }
      .requestList {
        display: flex;
        flex-direction: column;
        padding: 5px 4px;
        width: 100%;
        .r-item {
          margin-bottom: 10px;
          padding-bottom: 5px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          border-bottom: 1px solid #dcdfe6;
          width: 100%;
          .info {
            flex: 1;
            .info-li {
              .label {
                width: 80px;
                text-align-last: justify;
              }
              display: flex;
            }
          }
          .info2{
            .label{
              text-align-last: auto !important;
            }
          }
          .right {
            display: flex;
          }
        }
      }
    }
  }
  .user-list{
    margin-bottom: 24px;
  }
  .ul-title{
    padding-left: 14px;
    margin-bottom: 8px;
  }
  .projectSearchBox {
      border-bottom: 1px solid #dcdfe6;
      .csb-item {
        display: flex;
        padding-left: 30px;
        margin-bottom: 20px;
        align-items: center;
        .label {
          width: 160px;
        }
        .info{
          position: relative;
          .closeBtn{
            position: absolute;
            right: -12px;
            top: -6px;
            background: #ed4014;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }
}
</style>